<template>
    <div id="page-wifi-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center">
                <div class="flex-grow">
                    <vs-button
                        @click="addQR"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-slack">
                        Thêm mã QR
                    </vs-button>
                </div>

                <!-- ITEMS PER PAGE -->
                <Pagination
                    :itemList="qrCodeList"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />


                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="qrCodeList"
                         :animateRows="true"
                         :pagination="true"
                         :localeText=" {noRowsToShow: 'Không có dữ liệu'}"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../ui-elements/Pagination.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererStatus from "../../admin-pages/organization/cell-renderer/CellRendererStatus";

export default {
    components: {
        AgGridVue,

        // Cell Renderer
        CellRendererActions,
        Pagination,
        CellRendererStatus
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    width: 100
                },
                {
                    headerName: 'Chi nhánh',
                    field: 'organizationBranchName',
                    width: 200,
                },
                {
                    headerName: 'Chuỗi mã hóa QR',
                    field: 'qrCode',
                    width: 200
                },
                {
                    headerName: 'Trạng thái',
                    field: 'displayStatus',
                    width: 200,
                    cellRendererFramework: 'CellRendererStatus'
                },
                {
                    headerName: 'Hành động',
                    field: 'actions',
                    width: 100,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
                CellRendererStatus
            },
            qrCodeList: [],
            branchId: null,
        }
    },
    created() {
        this.initScreen();
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.deleteQrCode'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('DELETE_QR_CODE', false);
            }
        },
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        addQR() {
            let url = '/user/add-qr-code';
            if (this.branchId)
                url = `${url}?branch_id=${this.branchId}`;

            this.$router.push(url).catch(() => {
            })
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            this.branchId = this.$route.query.branch_id;

            let url = '/qr-code/find-all',
                branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');

            this.$vs.loading();
            this.$crm.get(url).then((response) => {
                response.data.forEach((item) => {
                    let branchInfo = branchList.find(x => x.id === item.organizationBranchId);
                    if (branchInfo)
                        item.organizationBranchName = branchInfo.organizationBranchName;
                });

                this.qrCodeList = response.data.map(item => {
                    item.displayStatus = item.status == 0 ? 'Không hoạt động' : 'Hoạt động';
                    return item;
                });

                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>

<style scoped>

</style>
